// aizvieto smaidiņus diskusijās un pirmajā lapā

const $ = require('jquery');
const emojione = require('emojione');

const getReplaceTarget = function () {
  if (
    window.location.pathname == '/' ||
			window.location.pathname == `/${op.locale}`
  ) {
    return $('.discussionBlock');
  } else if (window.location.pathname.indexOf('discussions') !== -1) {
    return $('.content-main');
  } else if (window.location.pathname.indexOf('payments') !== -1) {
    return $('.paymentContainer');
  }
  return false;
};

module.exports = {
  init() {
    const self = this;
    self.replaceEmoticons();
    $(document).on('pjax:end', (e) => {
      self.replaceEmoticons();
    });
  },
  replaceEmoticons() {
    const target = getReplaceTarget();
    if (!target || target.length <= 0) {
      return false;
    }
    let content = target.html();
    content = emojione.shortnameToUnicode(content);
    target.html(emojione.unicodeToImage(content));
  },
};
