var React = require('react');
var ReactDOM = require('react-dom');
var createReactClass= require('create-react-class');
var helper = require('./../helper');

(function(){
	var membersBlock = document.getElementById('membersBlock');

	if (membersBlock === null || old_ie === true) {
		return false;
	}

	var MEMBERS_GRID_SIZE = 56; // grid elementa platums, augstums (px)
	var MEMBERS_GRID_HEIGHT = 7; // grid augstuma vienību skaits
	var MEMBERS_GRID_INNER_SIZE = 7; // iekšējā loga max platums (vienībās)
	var MEMBERS_GRID_ROW_LARGE_COORD = [[-11, 0],[-10, 2], [-12, 5], [-9, 5], [11, 0], [9, 3], [12, 5], [8, 5]]; // lielo thumb sākuma koordinātas

	var MembersBlockBox = createReactClass({
		/**
		* Aprēkina grid size
		* ja platums 20 vienības, tad size vērtība 20/2 + 1
		* @return {number} Cik plats ir grid vienā virzienā
		*/
		getGridSize: function() {
			return  parseInt(window.innerWidth/MEMBERS_GRID_SIZE/2) + 1;
		},

		/**
		* Aprēķina iekšējā loga platumu
		* @param {number} grid size
		* @return {number} Iekšējā loga platums
		*/
		getGridInnerSize: function(size) {
			if (size > MEMBERS_GRID_INNER_SIZE + 2) {
				return MEMBERS_GRID_INNER_SIZE;
			}

			return size - 3;
		},

		/**
		* Aprēķina aptuveno ielādējamo bilžu skaitu
		* @return {number} Aptuvenais bilžu skaits
		*/
		getImgCount: function(size, innerSize) {
			var imgSize = (innerSize + 1) * 4;
			imgSize += (size-innerSize - 1) * 2 * MEMBERS_GRID_HEIGHT;

			return imgSize;
		},

		getInitialState: function() {
			var size = this.getGridSize(),
			innerSize = this.getGridInnerSize(size),
			imgCount = this.getImgCount(size, innerSize);

			return {
				size: size,
				innerSize: innerSize,
				imgSize: imgCount,
				data: []
			};
		},

		handleResize: function(e) {
			var size = this.getGridSize(this.state.size);
			this.setState({
				size: size,
				innerSize: this.getGridInnerSize(size)
			});
		},

		componentDidMount: function() {
			var url = this.props.url + '?limit=' + this.state.imgSize;
			window.addEventListener('resize', this.handleResize);

			var do_ajax = true;

			// skatās, vai ir localstorage
			if (typeof helper.local_cache !== 'undefined') {
				var local_data = helper.local_cache.get_item(url);

				if (local_data) {
					this.setState({data: local_data});
					do_ajax = false;
				}
			}

			// izpilda ajax, ja nav iekš localstorage
			if (do_ajax) {
				helper.fetch_json_file(url, function(data){
					this.setState({data: data});
					// saglabā localstorage uz stundu
					if (typeof helper.local_cache !== 'undefined') {
						helper.local_cache.set_item(data, url, 1);
					}
				}.bind(this));
			}

//			$.ajax({
//				url: this.props.url,
//				data: {limit: this.state.imgSize},
//				dataType: 'json',
//				success: function(data) {
//					this.setState({data: data});
//				}.bind(this),
//				error: function(xhr, status, err) {
//					console.error(this.props.url, status, err.toString());
//				}.bind(this)
//			});
		},

		componentWillUnmount: function() {
			window.removeEventListener('resize', this.handleResize);
		},
		render: function() {
			return (
				<div className="membersBlock">
					<MembersBlockContent size={this.state.size} innerSize={this.state.innerSize} data={this.state.data} />
					<MembersBlockStatistics size={this.state.innerSize} />
				</div>
			);
		}
	});

	var MembersBlockContent = createReactClass({
		/**
		* Izveido masīvu ar grid punktiem,
		* kuros neattēlot ierakstus
		* @return {array}
		*/
		gridPattern: function() {
			var rows = MEMBERS_GRID_ROW_LARGE_COORD,
				grid = [],
				rx = 0,
				ry = 0,
				x = 0,
				y = 0;

			// lielie thumb
			for (var i = 0; i < rows.length; i++) {
				rx = rows[i][0],
				ry = rows[i][1];

				for (x = 0; x < 2; x++) {
					if (typeof grid[rx + x] === "undefined") {
						grid[rx + x] = [];
					}
					for (y = 0; y < 2; y++) {
						grid[rx + x][ry + y] = 1;
					}
				}
			}

			// iekšējais rāmis
			for (x = 0; x <= this.props.innerSize; x++) {
				if (typeof grid[x] === "undefined") {
					grid[x] = [];
				}
				if (typeof grid[-x] === "undefined") {
					grid[-x] = [];
				}
				for (y = 1; y <= 5; y++) {
					grid[x][y] = 1;
					grid[-x][y] = 1;
				}
			}

			return grid;
		},

		/**
		* Vai attēlot iekš GRID
		* @param {number} Grid x koordināta
		* @param {number} Grid y koordināta
		* @return {bool}
		*/
		canAddToGrid: function(x, y) {
			if (typeof this.state.pattern[x] !== "undefined" && typeof this.state.pattern[x][y] !== "undefined") {
				return false;
			}

			return true;
		},

		getInitialState: function() {
			return {
				pattern: []
			};
		},
		render: function() {
			var grid = [],
				largeThumbs = MEMBERS_GRID_ROW_LARGE_COORD,
				largeThumbsTotal = largeThumbs.length,
				key = 0,
				x = 0,
				y = 0,
				i = 0,
				j = 0,
				dataId = 0,
				type = "m";

			if (this.props.data.length > 0) {
				this.state.pattern = this.gridPattern();

				// pievieno lielos thumb
				for (i = 0; i < largeThumbsTotal; i++) {
					if (typeof this.props.data[i] === "undefined") {
						continue;
					}

					x = largeThumbs[i][0];
					y = largeThumbs[i][1];
					if (x < 0) {
						x -= 1;
					}
					grid.push(<MembersBlockRow key={i} row={y} col={x} data={this.props.data[i]} type={type} />);
					key++;
				}

				// pievieno mazos thumb
				type = "t";
				dataId = key;
				for (i = 0; i < MEMBERS_GRID_HEIGHT; i++) {
					for (j = 0; j < this.props.size; j++) {
						// atjauno skaitu
						if (typeof this.props.data[dataId] === "undefined") {
							dataId = largeThumbsTotal;
						}
						// pa labi
						if (this.canAddToGrid(j,i) && typeof this.props.data[dataId] !== "undefined") {
							grid.push(<MembersBlockRow key={key} row={i} col={j} data={this.props.data[dataId]} type={type} />); // pa labi
							key++;
							dataId++;
						}
						// pa kreisi
						if (this.canAddToGrid(-j,i) && typeof this.props.data[dataId] !== "undefined") {
							grid.push(<MembersBlockRow key={key} row={i} col={-(j+1)} data={this.props.data[dataId]} type={type} />); // pa kreisi
							key++;
							dataId++;
						}
					}
				}
			}

			return (
				<div className="membersBlock-content">
					{grid}
				</div>
			);
		}
	});

	var MembersBlockRow = createReactClass({
		render: function() {
			if (typeof this.props.data === "undefined") {
				return (null);
			}

			var style = {
				top: this.props.row * MEMBERS_GRID_SIZE,
				left: this.props.col * MEMBERS_GRID_SIZE
			};

			var classes = 'membersBlock-row',
				tooltipClasses = 'membersBlock-row-tooltip',
				data = this.props.data,
				tooltipData = '';

			// lielais thumb
			if (this.props.type == 'm') {
				classes += ' membersBlock-row--large';
			}

			// 1.  rindai, tooltip uz leju
			if (this.props.row == 0) {
				tooltipClasses += ' membersBlock-row-tooltip--down';
			}

			var tooltip = (
				<div className={tooltipClasses}>
					{data.name ? data.name : ''}
					{data.age ? ' (' + data.age + ')' : ''}
					{data.loc ? <br/> :''}
					{data.loc ? data.loc : ''}
				</div>
			);

			// alt tagam info
			var imageAlt = (data.name ? data.name : '')
				+ (data.age ? ' (' + data.age + ')' : '')
				+ (data.loc ? ' ' + data.loc : '');

			var image_url = membersBlockParams.img_url + this.props.type + "/" + data.img;

			if (data.gcloud) {
				image_url = membersBlockParams.gcloud_url + this.props.type + "/" + data.img;
			}

			var memberLink = membersBlockParams.base + 'member/' + data._id;

			var link = (
				<a href={memberLink}>
					{tooltip}
					<img src={image_url} alt={imageAlt} style={{width:"100%", height:"100%"}} />
				</a>
			);

			return (
				<div className={classes} style={style}>
					{link}
				</div>
			);
		}
	});

	// statistics
	var MembersBlockStatistics = createReactClass({
		render: function() {
			var items = [];

			var divStyle = {
				width: (this.props.size + 1) * MEMBERS_GRID_SIZE * 2,
				height: MEMBERS_GRID_SIZE * 5,
				top: MEMBERS_GRID_SIZE ,
				marginLeft: (this.props.size + 1) * MEMBERS_GRID_SIZE * (-1)
			};

			for (var i = 0; i < statisticsData.length; i++) {
				items.push(<MembersBlockStatisticsItem key={i} data={statisticsData[i]} />);
			}

			return (
				<div className="statistics" style={divStyle}>
					<div className="g statistics-data">
						{items}
					</div>
				</div>
			);
		}
	});

	var MembersBlockStatisticsItem = createReactClass({
		render: function() {
			var data = this.props.data;
			var classesMain = "g-b g-b--1of4 statistics-data-item statistics-data-item--" + data.name;
			var svgIcon = "<svg class='icon icon--" + data.name + "'><use xlink:href='#icon-" + data.name + "' /></svg>";
			var divStyle = {
				height: MEMBERS_GRID_SIZE * 5
			};

			return (
				<div className={classesMain} style={divStyle}>
					<div className="statistics-data-item-icon">
						<div className="statistics-data-item-icon-container">
							<span dangerouslySetInnerHTML={{__html: svgIcon}} />
						</div>
					</div>
					<div className="statistics-data-item-numbers">
						{data.numbers}
					</div>
					<div className="line line--a mbm"></div>
					<div className="statistics-data-item-text">
						<span>{data.text}</span>
					</div>
				</div>
			);
	}
	});

	// partners
	var MembersBlockPartners = createReactClass({
		render: function() {
			if (typeof partnersData === "undefined") {
				partnersData = [];
			}
			var items = [];
			var total = partnersData.length
			var divStyle = {
				height: MEMBERS_GRID_SIZE,
			};

			for (var i = 0; i < total; i++) {
				items.push(<MembersBlockPartnersItem key={i} data={partnersData[i]} total={total} />);
			}

			return (
				<div className="g" style={divStyle}>
					{items}
				</div>
			);
		}
	});

	var MembersBlockPartnersItem = createReactClass({
		render: function() {
			var data = this.props.data;
			var total = this.props.total;
			var classesMain = "g-b g-b--1of" + total + ' statistics-footer-item';
			var svgIcon = "<svg class='icon icon--full icon--" + data + "'><use xlink:href='#icon-" + data + "' /></svg>";

			return (
				<div className={classesMain}>
					<span dangerouslySetInnerHTML={{__html: svgIcon}} />
				</div>
			);
		}
	});

	ReactDOM.render(
		<MembersBlockBox url={membersBlockParams.url} />,
		membersBlock
	);

})();
