const $ = require('jquery');
const countdown = require('./countdown');
const get = require('lodash').get;

let timer = null;

module.exports = {
  inIframe() {
    let inIframe = false;
	    try {
	        inIframe = window.self !== window.top;
	    } catch (e) {
	        inIframe = true;
	    }

	    if (inIframe == true) {
	    	$('body').addClass('in-iframe');
    }
  },
  initCountdown() {
    const container = '.campaign-countdown-inner';
    const $container = $(container);
    const format = $container.data('countdownFormat');
    if ($container.length) {
      countdown.init(container, get(globals, 'campaignEndtime', null), get(globals, 'locale', 'lv'), format);
    }
  },
  init() {
    const container = '.campaign-countdown-inner';
    this.initCountdown();
    this.inIframe();
    if ($(container).length) {
      timer = true;
    }
    const _this = this;
    $(document).on('pjax:end', () => {
      if (timer !== true) {
        _this.initCountdown();
      }
      _this.inIframe();
    });
  },
};
