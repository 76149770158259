/**
 * OP - first page
 */
var op = new function () {
	/*
	 * is phone
	 */
	this.is_phone = false;

	/*
	 * is tablet
	 */
	this.is_tablet = false;

	/*
	 * Stories slider
	 */
	this.stories_slider = null;

	/*
	 * Login block
	 */
	this.login_block = null;

	/*
	 * Modal/Pop-up logs
	 */
	this.vex_modal = null;

	/*
	 * Iestata vērtības, kuras pārlūks atbalsta
	 */
	this.init_browser_supports = function() {
		// neatbalsta background-size
		if (!('backgroundSize' in document.documentElement.style)) {
			$('body').addClass('no-backgroundSize');
		}

		// svg atbalsts
		if(!document.createElement('svg').getAttributeNS){
			$('body').addClass('no-svg');
		}
	};

	/*
	 * Iestata formu validāciju
	 */
	this.init_device = function() {
		if (typeof device === 'undefined'){
			return false;
		}
		if (device == 'phone') {
			this.is_phone = true;
		}
		if (device == 'tablet') {
			this.is_tablet = true;
		}
	};

	/*
	 * Iestata formu validāciju
	 */
	this.init_form_validation = function() {
		var form = $('.form.validate');

		$('input[name="email"]').focusout(function() {
			var email_str = $(this).val();
			email_str = email_str.trim();
			$(this).val(email_str);
		});

		if (form.length > 0) {
			form.each(function(){
				$(this).validate();
			});
		}
	};

	/*
	 * Iestata scroll up
	 */
	this.init_scrollup = function() {
		$('.scrollUp').on('click', function(e) {
			e.preventDefault();
			$('html, body').animate({ scrollTop: 0 }, 'fast');
		});
	};

	/*
	 * Iestata stories slider
	 */
	this.init_stories_slider = function() {
		this.stories_slider = $('#stories-slider');

		if (this.stories_slider.length === 0) {
			return false;
		}

		this.stories_slider.slick({
			dots: true,
			draggable: false,
			prevArrow: '<button type="button" class="stories-button stories-button--left"><svg class="icon icon--full"><use xlink:href="#icon-arrowleft" /><!--[if (lte IE 8)|(!IE)]><image src="' + img_url +'img/svg/png/arrowLeft.png"/><![endif]--></svg></button>',
			nextArrow: '<button type="button" class="stories-button stories-button--right"><svg class="icon icon--full"><use xlink:href="#icon-arrowright" /><!--[if (lte IE 8)|(!IE)]><image src="' + img_url +'img/svg/png/arrowRight.png"/><![endif]--></svg></button>',
			responsive: [
				{
					breakpoint: 1000,
					settings: {
						draggable: true
					}
				},
				{
					breakpoint: 890,
					settings: {
						draggable: true,
						arrows: false
					}
				}
			]
		});
	};

	/*
	 * Iestata blog slider
	 */
	this.init_blog_slider = function() {
		this.blog_slider = $('#blog-slider');

		if (this.blog_slider.length === 0) {
			return false;
		}

		this.blog_slider.slick({
			dots: true,
			draggable: false,
			prevArrow: '<button type="button" class="stories-button blog-button--left"><svg class="icon icon--full"><use xlink:href="#icon-arrowleft" /><!--[if (lte IE 8)|(!IE)]><image src="' + img_url +'img/svg/png/arrowLeft.png"/><![endif]--></svg></button>',
			nextArrow: '<button type="button" class="stories-button blog-button--right"><svg class="icon icon--full"><use xlink:href="#icon-arrowright" /><!--[if (lte IE 8)|(!IE)]><image src="' + img_url +'img/svg/png/arrowRight.png"/><![endif]--></svg></button>',
			responsive: [
				{
					breakpoint: 1000,
					settings: {
						draggable: true
					}
				},
				{
					breakpoint: 890,
					settings: {
						draggable: true,
						arrows: false
					}
				}
			]
		});
	};

	/*
	 * Iestata - atslēdz hover uz skroll notikuma
	 */
	this.init_disable_hover = function() {
		if (old_ie) {
			return false;
		}

		var body = $('body'),
			timer;

		window.addEventListener('scroll', function() {
			clearTimeout(timer);
			if(!body.hasClass('disable-hover')) {
				body.addClass('disable-hover');
			}

			timer = setTimeout(function(){
				body.removeClass('disable-hover');
			},500);
		}, false);
	};

	/*
	 * Iestata auto fokusu input laukam
	 * input laukam jānorāda klase .form-focus
	 */
	this.form_input_focus = function() {
		// uz mob ierīcēm, nav auto fokuss
		if (this.is_tablet || this.is_phone) {
			return false;
		}
		setTimeout(function(){$('input.form-focus').focus();}, 100);
	};

	/*
	 * Mob aplikācijas baneri
	 */
	this.init_app_banners = function() {
		var title = 'Otrapuse.lv';
		if (typeof domain !== 'undefined') {
			title = domain;
		}
		$.smartbanner({
			title: title,
			author: 'Hansa Interactive',
			daysHidden: 1, // Duration to hide the banner after being closed (0 = always show banner)
			hideOnInstall: false, // Hide the banner after "VIEW" is clicked.
			// Cookie gets saved anyway (even with 'hideOnInstall' set to 'false').
			// And banner is not hidden immediately, but after refreshing page, it will not be shown if "VIEW" was clicked.
			// So set this as well to 1 day (otherwise it will get saved for 3 months).
			daysReminder: 1, // Duration to hide the banner after "VIEW" is clicked *separate from when the close button is clicked* (0 = always show banner)
		});
		$(document).on('click', '.sb-close', function() {
			$('#smartbanner').hide();
		});
	};

	/*
	 * Iestata popup/modal logus
	 */
	this.init_popupbox = function() {
		if (!vex) {
			return;
		}

		if (!vex.hasOwnProperty('defaultOptions')) {
			vex.defaultOptions = {};
		}

		// norāda firstpage theme
		vex.defaultOptions.className = 'vex-theme-firstpage';

		var self = this;

		$('.popup-load').on('click', function(e) {
			e.preventDefault();

			var url = $(this).attr('href');

			var request = $.ajax({
				type: "GET",
				url: url
			});

			// uzreiz atverot rāda loading logu
			self.vex_modal = vex.open({
				content: '<div class="loader"><div class="loader-inner loader-inner__01"></div><div class="loader-inner loader-inner__02"></div><div class="loader-inner loader-inner__03"></div><div class="loader-inner loader-inner__04"></div><div class="loader-inner loader-inner__05"></div><div class="loader-inner loader-inner__06"></div><div class="loader-inner loader-inner__07"></div><div class="loader-inner loader-inner__08"></div></div>'
			});

			// ar ajax veic pieprasījumu uz serveri un aizvieto saturu
			request.done(function(data) {
				self.vex_modal.find('.loader').replaceWith(data);
			});
		});

		// https://github.com/HubSpot/vex/issues/43
		// lai darbotos scrollbar, citādi nevar ar peli mainīt pozīciju
		$(document).on('click', '.vex', function(){
			if (!self.vex_modal) {
				return;
			}
			vex.close(self.vex_modal.data().vex.id);
		});

		$(document).on('click', '.vex-content', function(e){
			e.stopPropagation();
		});
	};

	this.windowPopup = function(url, width, height) {
	  var leftPosition, topPosition;
	  leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
	  topPosition = (window.screen.height / 2) - ((height / 2) + 50);
	  window.open(url, "Window2", "status=no,height=" + height + ",width=" + width + ",resizable=yes,left=" + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY=" + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
	};

	this.init_popup = function() {
	  var _this = this;
	  $(document).on('click', '[data-popup]', function(e) {
		e.preventDefault();
		var width =  $(this).data('popupWidth');
		var height =  $(this).data('popupHeight');
		_this.windowPopup(this.href, width, height);
	  });
	};

	this.init_facebook_fix = function() {
		if (window.location.hash == '#_=_'){
			history.replaceState
        ? history.replaceState(null, null, window.location.href.split('#')[0])
        : window.location.hash = '';
		}
	};

	this.init_city_autocomplete = function() {
		$('input#MembersProfileCityIdAutocomplete').cityAutocomplete();
	};

	/**
	 * Inicializē OP
	 */
	this.init = function() {
		op.init_browser_supports();
		op.init_device();
		op.init_form_validation();
		op.init_scrollup();
		op.init_disable_hover();
		op.form_input_focus();
		op.init_stories_slider();
		op.init_blog_slider();
		op.init_popupbox();
		op.init_popup();

		// op.init_city_autocomplete();

		op.init_facebook_fix();
		if (device == 'phone' || device == 'tablet') {
			op.init_app_banners();
		}
	};
};

$(document).ready(function() {
	op.init();
});
