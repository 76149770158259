let $ = window.jQuery;
if (typeof $ === 'undefined') {
  $ = require('jquery');
}

require('slick');

module.exports = {
  init() {
    const $slider = $('.slider-content');
    let autoplay = false;

    if (!$slider) {
      return false;
    }

    if ($slider.hasClass('slider-autoplay')) {
      autoplay = true;
    }

    $slider.slick({
      dots: true,
      arrows: true,
      lazyLoad: 'ondemand', // lazy loading
      prevArrow: '<button type="button" class="sliderArrow sliderArrow--prev"><svg class="icon icon--full"><use xlink:href="#icon-arrowLeftLarge" /></svg></button>',
      nextArrow: '<button type="button" class="sliderArrow sliderArrow--next"><svg class="icon icon--full"><use xlink:href="#icon-arrowRightLarge" /></svg></button>',
      autoplay,
    });
  },
};
