function focusAndGo(url) {
	window.focus();
	window.location.href=url;
}

function fetch_json_file(path, callback) {
    var httpRequest = new XMLHttpRequest();
    httpRequest.onreadystatechange = function() {
        if (httpRequest.readyState === 4) {
            if (httpRequest.status === 200) {
                var data = JSON.parse(httpRequest.responseText);
                if (callback) callback(data);
            }
        }
    };
    httpRequest.open('GET', path);
    httpRequest.send();
}

// localstorage cache
var local_cache = (function(){
	"use strict";

	var module = {};

	// Modernizr.localstorage, version 3 12/12/13
	var has_localstorage = function() {
		var mod = 'modernizr';
		try {
			localStorage.setItem(mod, mod);
			localStorage.removeItem(mod);
			return true;
		} catch(e) {
			return false;
		}
	};

	// vai atbalsta localstorage
	if (!has_localstorage()) return;

	// dzēš ierakstus
	var delete_item = function(cache_key) {
		localStorage.removeItem(cache_key);
		localStorage.removeItem(cache_key  + 'cachettl');
	};

	var parse_item = function(cache_key) {
		// Ja ir izbeidzies laiks, dzēš ierakstus
		var ttl = localStorage.getItem(cache_key + 'cachettl');
		if (ttl && ttl < +new Date()){
			delete_item(cache_key);
		}
	};

	// data, nosaukums, stundas
	module.set_item = function(data, cache_key, cache_ttl) {
		var strdata = JSON.stringify(data);
		var hours_tl = cache_ttl || 2; // default 2 stundas

		parse_item(cache_key);

		// saglabā
		try {
			localStorage.setItem(cache_key, strdata);
			localStorage.setItem(cache_key  + 'cachettl', +new Date() + 1000 * 60 * 60 * hours_tl);
			return true;
		} catch (e) {
			// Dzēš, ja kļūda
			delete_item(cache_key);
			return false;
		}
	};

	module.get_item = function(cache_key) {
		parse_item(cache_key);

		var value = localStorage.getItem(cache_key);

		return JSON.parse(value);
	};

	return module;

})();

module.exports = {
  fetch_json_file,
	focusAndGo,
	local_cache
};
