const $ = require('jquery');
require('postmessage');

let autoInit = true;

module.exports = {
  getParameterByName(name) {
	    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	    let regex = new RegExp(`[\\?&]${name}=([^&#]*)`),
      results = regex.exec(window.location.search);
	    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },
  resizeIframe(wrapper) {
    $.setIframeSize({
      initScroll: true,
      delayScroll: 500,
      height: `${$(wrapper).height()}px`,
      width: '95%',
    });
  },
  init(doAutoInit) {
    if (doAutoInit == false) {
      autoInit = false;
    }
    // if not inside iframe load iframe location
    if ((typeof iframe_redirect !== 'undefined') &&
				(module.exports.getParameterByName('e').length !== 0) &&
				(window.self === window.top)) {
      window.location.assign(iframe_redirect);
    }

    let wrapper;
    if ($('.container-wrapper').length) {
      wrapper = '.container-wrapper';
    } else {
      wrapper = '.wrapper';
    }
    window.onload = function () {
      module.exports.resizeIframe(wrapper);
    };

    let id;
    window.onresize = function () {
      clearTimeout(id);
      id = setTimeout(module.exports.resizeIframe(wrapper), 500);
    };

    $(document).on('pjax:end', () => {
      module.exports.resizeIframe('.wrapper');
    });

    $(document).click(module.exports.resizeIframe(wrapper));

    $(document).on('countrychanged', 'select#CountryId', () => {
      module.exports.resizeIframe('.wrapper');
    });
    $(document).on('questionadded', '#additional_questions', (e, item) => {
      $('.wrapper').height($('.wrapper').height() + item.height);
      module.exports.resizeIframe('.wrapper');
    });
    $(document).on('questionclosed', '#additional_questions', (e, item) => {
      $('.wrapper').height($('.wrapper').height() - item.height);
      module.exports.resizeIframe('.wrapper');
    });
    $(document).on('click', 'a[href*="/members/logout"]', (e, item) => {
      module.exports.resizeIframe('.wrapper');
    });
  },
};

if (autoInit == true) {
  module.exports.init();
}
