import React from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from 'react-video-js-player';

const VideoJsPlayer = (props) => {
  const { videoSrc, videoPoster } = props;

  return (
    <div>
      <VideoPlayer
        className="video-video"
        width="700"
        height="394"
        controls
        src={videoSrc}
        poster={videoPoster}
      />
    </div>
  );
};

VideoJsPlayer.propTypes = {
  videoSrc: PropTypes.string,
  videoPoster: PropTypes.string,
};

VideoJsPlayer.defaultProps = {
  videoSrc: '',
  videoPoster: '',
};

export default VideoJsPlayer;
