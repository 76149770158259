const _ = require('lodash');
const $ = require('jquery');
const pjax = require('pjax');
const slider = require('./slider');
const NProgress = require('nprogress');
const payments = require('./payments');
const mailee = require('./mailee');
require('collapse');
const unveil = require('unveil');

module.exports = {
  init() {
    const self = this;
    const menu_items = $('#menu').find('.menu-item');


    if ($.support.pjax) {
      // settings
      $.pjax.defaults.timeout = 14000;
      // vismaz 1, lai saglabātos atmiņa par to kāds 'container' bija iepriekšējā lapā
      // un, spiežot back pogu, pārslēgtos uz pareizo 'container'
      $.pjax.defaults.maxCacheLength = 1;

      $(document).on('click', 'a[data-target]', function (event) {
        const target = $(this).data('target');
        if (!target.length || !$(target).length) {
          return window.location = event.currentTarget.href;
        }

        const closest_menu_item = $(this).closest('.menu-item');
        if (closest_menu_item.length > 0) {
          globals.active_menu = menu_items.filter($(this).closest('.menu-item'));
        }
        self.pjaxClick(event, target);
      });

      $(document).on('pjax:clicked', (event) => {
        const pjaxTarget = $(event.target).data('target');
        if (!pjaxTarget) {
          return false;
        }

        const $pjaxTarget = $(pjaxTarget);
        if ($pjaxTarget.data('showLoading') != false && (event.ctrlKey != true && event.metaKey != true)) {
          $pjaxTarget.addClass('loading');
        }

        return true;
      });
    }
    $(document).on('submit', 'form[data-target]', function (event) {
      // For discussions, to upload images (and validate that everything ok), before submitting form
      if ($(this).data('disablePjax') == '1') {
        return;
      }
      const $target = $($(this).data('target'));
      // data-disableLoader - iespēja izslēgt loading
      if (!($(this).data('disableloader') || $(this).data('disableLoader'))) {
        $target.addClass('loading');
      }
      const options = {};
      if (
        (
          $(this).data('target') !== '#content' &&
					$(this).data('target') !== '#main-panel'
        )
				||
				// izslēgt push, piem. diskusijas komentāriem
				$(this).data('disablePush') == '1'
      ) {
        options.push = false;
        options.scrollTo = false;
      }

      $.pjax.submit(event, $(this).data('target'), options);
    });

    $(document).on('pjax:start', () => {
      NProgress.start({
        showSpinner: false,
      });
    });
    $(document).on('pjax:popstate', (e) => {
      e.state.container = '#content';
    });
    $(document).on('pjax:end', () => {
      $('.loading').removeClass('loading');

      // Šis nepieciešams jaunajā search.
      // Kad ielādē lapu ar pjax, 'loading-spinner' nepazūd, kamēr nav ielādēti arī lietotāji.
      // Tapēc parent div pieliek atpakaļ 'loading' klasi, lai 'loading-spinner' būtu pareizā pozīcijā.
      // Varētu šadā gadījumā arī nemaz nenoņemt to 'loading' klasi,
      // bet tad tas loading pārklājums paliek arī pāri kreisajam panelim, kas nav nepieciešams.
      // Šādi viss ir super.
      const $loadingSpinner = $('.loading-spinner');
      if ($loadingSpinner.length > 0) {
        $loadingSpinner.parent('div').addClass('loading');
      }

      if ($('.timeago').length) {
        $('.timeago').timeago();
      }
      $('.discussionBlock-lazyload').unveil();
      const valid_containers = ['#content', '#main-panel'];
      if (valid_containers.indexOf($.pjax.state.container) !== -1) {
        // gemius
        if (typeof pp_gemius_event === 'function') {
          pp_gemius_event('d6063wbip1w2PhYbHd4_zuVDzUudCo8oIqyPDOQzK0..V7');
        }
        // todo: šis tiek izmantots tikai adminam, pārnest uz admin.js
        // nevar notvert 'pjax:end' eventu admin.js failā
        const $collapsable = $('[data-collapse]');
        if ($collapsable.length > 0) {
          $collapsable.each(function () {
            const options = {};
            options.collapse = $(this).data('collapse');
            $(this).collapse(options);
          });
        }
      }
      NProgress.done(); // loading josla
    });

    // before replacing HTML with content loaded from the server
    $(document).on('pjax:beforeReplace', (event, contents, options) => {
      // atrod sub nav izvēlni un aizstāj esošo
      const $subNav = $('#subNav');
      const subNavContainer = contents.filter('.subNavContainer').html();
      if (!subNavContainer) {
        $('.subNav-item').removeClass('is-active');
      }
      $subNav.html(subNavContainer);
    });

    // after replacing HTML content loaded from the server
    $(document).on('pjax:success', (event, contents, options) => {
      // atrod visus js-remove un notiek dzēšana
      $('.js-remove').remove();
      // init slider
      slider.init();
      //
      self.setActiveMainMenuItem();
      self.setActiveSubMenuItem();
    });
  },
  pjaxClick(event, target) {
    if ($('body').hasClass('b-ie-old')) {
      return window.location = event.currentTarget.href;
    }

    $.pjax.click(event, { container: target });
  },
  reload(target) {
    const $target = $(target);
    $target.addClass('loading');

    $.pjax.reload(target, { container: target });
  },
  setActiveMainMenuItem() {
      $('.menu-item').removeClass('is-active');
      const activeMainMenu = _.get(globals, 'activeMainMenu', '');
      $('.menu-item[data-name="' + activeMainMenu + '"]').addClass('is-active');
  },
  setActiveSubMenuItem() {
      if ($('#subNav .is-active').length) {
          return;
      }
      const activeSubMenu = _.get(globals, 'activeSubMenu', '');
      $('.subNav-item[data-active-id="' + activeSubMenu + '"]').addClass('is-active');
  },
};
