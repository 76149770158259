/* eslint-disable import/no-extraneous-dependencies, no-var, no-redeclare, vars-on-top */
/* eslint no-underscore-dangle: ["error", { "allow": ["__svg__", "__svgstore__"] }] */
/* global globals */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';
//
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

//
// import legacy from './legacy/main';
import './legacy/app/firstpage/main';
// import gtm from './legacy/app/gtm';
import popup from './legacy/app/popup';
import pjax from './legacy/app/pjax';
import gtm from './helpers/gtm';
import cookieConsentExtended from './helpers/cookieConsentExtended';

import '../../sass/firstpage.scss';
// import './../../sass/firstpage-s.scss';
// import './../../sass/firstpage-nofont-s.scss';
// import './../../sass/firstpage-nofont.scss';

//
import '../libraries/slick';

//
const ignorePatterns = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
];
Bugsnag.start({
  apiKey: '369b008f4b772a462c218a0b6f8ed23c',
  plugins: [new BugsnagPluginReact()],
  appVersion: '1.3.1',
  releaseStage: globals.environment,
  enabledReleaseStages: ['production'],
  logger: null,
  onError: (event) => {
    if (ignorePatterns.some((re) => event.errors[0].errorMessage.includes(re))) {
      return false;
    }
    return true;
  },
});

// inline-main.svg
var __svgstore__ = {
  path: './../../assets/img/svg/raw/**/*.svg',
  name: 'svg/inline-main.svg',
};
__svgstore__ = { filename: 'static/svg/inline-main.svg' };
require('webpack-svgstore-plugin/src/helpers/svgxhr')(__svgstore__);
// partners.svg
var __svgstore__ = {
  path: './../../assets/img/svg/partners/**/*.svg',
  name: 'svg/partners.svg',
};
__svgstore__ = { filename: 'static/svg/partners.svg' };
require('webpack-svgstore-plugin/src/helpers/svgxhr')(__svgstore__);

console.logText = (text, background, color) => {
  console.log(`%c${text}`, `background: ${background || 'black'}; color: ${color || 'white'}; display: block; padding: 2px 4px;`);
};

const init = () => {
  // legacy.init();
  // console.logText('firstpage - sendPageView', 'purple');
  // gtm.sendPageView();
  popup.init();
  pjax.init();
  gtm.init();
  cookieConsentExtended.init();
};
init();
