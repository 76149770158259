import React from 'react';
import ReactDOM from 'react-dom';
import VideoJsPlayer from '../../../../react/components/VideoJsPlayer';

(function(){
  const videoPlayerContainers = document.getElementsByClassName('video-player');
  Array.prototype.map.call(videoPlayerContainers, (container) => {
    if (container !== null) {
      let videoSrc = '';
      let videoPoster = '';

      let attributes = container.attributes,
        i = attributes.length;

      for (; i--;) {
        if (attributes[i].name == 'data-video-src') {
          videoSrc = attributes[i].value;
        }

        if (attributes[i].name == 'data-video-poster') {
          videoPoster = attributes[i].value;
        }
      }

      ReactDOM.render(
        <VideoJsPlayer
          videoSrc={videoSrc}
          videoPoster={videoPoster}
        />,
        container,
      );
    }
  })
})();
