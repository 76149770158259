/* eslint-disable camelcase */
/* global globals, translations */
import $ from 'jquery';
import get from 'lodash/get';
import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

module.exports = {
  init() {
    if (!get(globals, 'cookieConsentExtended', false)) {
      return;
    }

    window.CC = window.initCookieConsent();
    window.CC.run({
      current_lang: 'lv',
      autoclear_cookies: true,
      page_scripts: true,
      // force_consent: true,
      hide_from_bots: false, // This is necessary to be able to test it with puppeteer

      gui_options: {
        consent_modal: {
          layout: 'cloud',
          position: 'bottom center',
          transition: 'slide',
        },
        settings_modal: {
          layout: 'box',
          transition: 'slide',
        },
      },

      onChange: (cookie, changed_preferences) => {
        // If 'analytics' wasn't changed or it was enabled, do nothing here
        if (changed_preferences.indexOf('analytics') < 0 || window.CC.allowedCategory('analytics')) {
          return;
        }

        // Disable ga ua
        console.log('disabling ga ua');
        window['ga-disable-UA-108619-10'] = true;

        // Disable gtag
        console.log('disabling gtag');
        window.dataLayer = window.dataLayer || [];

        function gtag(...args) {
          window.dataLayer.push(args);
        }

        gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
        });

        window.location.reload();
      },

      languages: {
        lv: {
          consent_modal: {
            title: get(translations, 'txt: Mēs izmantojam sīkdatnes, lai sniegtu Tev labāko iepazīšanās pieredzi', ''),
            description: get(translations, 'txt: Lai šī tīmekļvietne darbotos, tā izmanto obligāti nepieciešamās sīkdatnes', ''),
            primary_btn: {
              text: get(translations, 'txt: Apstiprināt visas', ''),
              role: 'accept_all',
            },
            secondary_btn: {
              text: get(translations, 'txt: Tikai nepieciešamās', ''),
              role: 'accept_necessary',
            },
          },
          settings_modal: {
            title: get(translations, 'txt: Sīkdatņu iestatījumi', ''),
            save_settings_btn: get(translations, 'txt: Apstiprināt izvēlētās', ''),
            accept_all_btn: get(translations, 'txt: Apstiprināt visas', ''),
            close_btn_label: 'Close',
            cookie_table_headers: [
              { col1: get(translations, 'txt: Sīkdatnes nosaukums', '') },
              { col2: get(translations, 'txt: Sīkdatnes apraksts', '') },
            ],
            blocks: [
              {
                description: get(translations, 'txt: Otrapuse izmanto divas sīkdatņu kategorijas, taču lietotāju piekrišana nepieciešama tikai vienai no tām', ''),
              }, {
                title: get(translations, 'txt: Nepieciešamās sīkdatnes', ''),
                description: get(translations, 'txt: Šīs sīkdatnes nodrošina vietnes korektu darbību, nodrošinot tās pamata funkcijas', ''),
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,
                },
                cookie_table: [
                  {
                    col1: 'nops',
                    col2: get(translations, 'txt: Sīkdatne nepieciešama, lai lietotājam būtu iespēja autorizēties', ''),
                  },
                  {
                    col1: 'uids',
                    col2: get(translations, 'txt: Sīkdatne nepieciešama, lai sistēma atpazītu iepriekšējās lietotāja apmeklējuma reizes portālā', ''),
                  },
                  {
                    col1: 'language',
                    col2: get(translations, 'txt: Sīkdatne tiek izmantota, lai mēs atpazītu lietotāja izvēlēto valodu', ''),
                  },
                  {
                    col1: 'fl',
                    col2: get(translations, 'txt: Sīkdatne nepieciešama, lai pirmajā lapā rādītu autorizācijas vai reģistrēšanās formu, atkarībā no tā, vai lietotājs jau kādreiz ir ienācis portālā', ''),
                  },
                  {
                    col1: 'cc_cookie',
                    col2: get(translations, 'txt: Sīkdatnē nepieciešama, lai sistēma atpazītu lietotāja apstiprinātos sīkdatņu iestatījumus', ''),
                  },
                  {
                    col1: '__cflb',
                    col2: get(translations, 'txt: Sīkdatne nepieciešama, lai sistēma novirzītu lietotāja pieprasījumus uz vienu un to pašu serveri', ''),
                  },
                ],
              }, {
                title: get(translations, 'txt: Analītiskās sīkdatnes', ''),
                description: get(translations, 'txt: Analītiskās sīkdatnes ļauj uzskaitīt apmeklējumu skaitu un avotus, lai mēs varētu izmērīt un uzlabot savas tīmekļa vietnes darbību', ''),
                toggle: {
                  value: 'analytics',
                  enabled: true,
                  readonly: false,
                },
                cookie_table: [
                  {
                    col1: '_fbp',
                    col2: get(translations, 'txt: Sīkdatne nepieciešama, lai atļautu apmeklējuma datu nodošanu statistikas uzkrāšanas rīkam Facebook pixel', ''),
                  },
                  {
                    col1: '^_ga', // match all cookies starting with "_ga"
                    col2: get(translations, 'txt: Sīkdatne nepieciešama, lai atļautu apmeklējuma datu nodošanu statistikas uzkrāšanas rīkam Google Analytics', ''),
                    is_regex: true,
                  },
                  {
                    col1: '_gat',
                    col2: get(translations, 'txt: Sīkdatne nepieciešama, lai atļautu apmeklējuma datu nodošanu statistikas uzkrāšanas rīkam Google Analytics', ''),
                  },
                  {
                    col1: '_gid',
                    col2: get(translations, 'txt: Sīkdatne nepieciešama, lai atļautu apmeklējuma datu nodošanu statistikas uzkrāšanas rīkam Google Analytics', ''),
                  },
                ],
              },
            ],
          },
        },
      },
    });

    // Looks like Safari auto focuses firstpage email field.
    // If there is cookie consent popup need to unfocus it.
    // TODO Though there are cases when input field gets focused with little delay,
    // after this is already executed.
    // So can still think about solutions for unfocusing it in those cases.
    // And specifically Safari should be tested, if this gets changed.
    if ($('#cm').length && $('#login-block').length) {
      $('#login-block input').removeClass('form-focus');
      setTimeout(() => {
        document.activeElement.blur();
      }, 200);
    }

    // Need to attach click event to cookie settings buttons after pjax
    // (this is definitely necessary for "Settings" section)
    // Taken from 'javascript/libraries/cookieconsent-2.8.0/src/cookieconsent.js'
    // and slightly adjusted
    $(document).on('pjax:end', () => {
      const show_settings = document.querySelectorAll('a[data-cc="c-settings"]');
      const click_callback = (e) => {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
        // e.preventDefault ? e.preventDefault() : e.returnValue = false;
        window.CC.showSettings(0);
      };

      for (let i = 0; i < show_settings.length; i += 1) {
        show_settings[i].setAttribute('aria-haspopup', 'dialog');
        if (show_settings[i].addEventListener) {
          show_settings[i].addEventListener('click', click_callback);
        } else {
          show_settings[i].attachEvent('onclick', click_callback);
        }
      }
    });
  },
};
