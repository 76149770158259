const $ = require('jquery');
require('plugin');
require('countdown');

module.exports = {
  load($selector, endtime, locale, format) {
    if (typeof endtime === 'undefined'
      || endtime === ''
      || $selector.length === 0
    ) {
      // Ja tomēr ir redzams īpašā piedāvājuma bloks bez laika, tad aizvāc, lai nerādās ar tukšumu
      if ($selector.length > 0) {
        $selector.closest('.campaign-block').hide();
      }

      return false;
    }
    // lv
    $.countdown.regionalOptions.lv = {
      labels: ['Gadi', 'Mēneši', 'Nedēļas', 'Dienas', 'Stundas', 'Minūtes', 'Sekundes'],
      labels1: ['Gads', 'Mēnesis', 'Nedēļa', 'Diena', 'Stunda', 'Minūte', 'Sekunde'],
      compactLabels: ['l', 'm', 'n', 'd'],
      compactLabels1: ['g', 'm', 'n', 'd'],
      whichLabels: null,
      digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
      timeSeparator: ':',
      isRTL: false,
    };

    // lt
    $.countdown.regionalOptions.lt = {
      labels: ['Metų', 'Mėnesių', 'Savaičių', 'Dienų', 'Valandų', 'Minučių', 'Sekundžių'],
      labels1: ['Metai', 'Mėnuo', 'Savaitė', 'Diena', 'Valanda', 'Minutė', 'Sekundė'],
      compactLabels: ['m', 'm', 's', 'd'],
      whichLabels: null,
      digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
      timeSeparator: ':',
      isRTL: false,
    };

    // ru
    $.countdown.regionalOptions.ru = {
      labels: ['Лет', 'Месяцев', 'Недель', 'Дней', 'Часов', 'Минут', 'Секунд'],
      labels1: ['Год', 'Месяц', 'Неделя', 'День', 'Час', 'Минута', 'Секунда'],
      labels2: ['Года', 'Месяца', 'Недели', 'Дня', 'Часа', 'Минуты', 'Секунды'],
      compactLabels: ['л', 'м', 'н', 'д'],
      compactLabels1: ['г', 'м', 'н', 'д'],
      whichLabels(amount) {
        const units = amount % 10;
        const tens = Math.floor((amount % 100) / 10);
        return (amount == 1 ? 1 : (units >= 2 && units <= 4 && tens != 1 ? 2 :
          (units == 1 && tens != 1 ? 1 : 0)));
      },
      digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
      timeSeparator: ':',
      isRTL: false,
    };

    if (locale && (locale == 'lv' || locale == 'ru' || locale == 'lt')) {
      $.countdown.setDefaults($.countdown.regionalOptions[locale]);
    }

    if (endtime !== false) {
      const now = new Date().getTime();
      const endtimeMilisec = endtime * 1000;
      // Pēc pjax, lai taimeris atkal nerādās ar nullēm, to pievieno tikai tad, ja laiks vēl nav beidzies
      // Ja laiks jau ir beidzies un piedāvājuma bloks tomēr ir/būtu redzams, tad aizvāc arī to, lai nerādās ar tukšumu
      if (endtimeMilisec < now) {
        if ($selector.length > 0) {
          $selector.closest('.campaign-block').hide();
        }
      } else {
        $selector.countdown({
          padZeroes: true,
          format: format,
          until: new Date(endtimeMilisec),
          onExpiry: function() {
            // Kad beidzas piedāvājuma laiks, aizvāc taimeri, lai nerādās ar nullēm
            // un maldinošu aicinājumu "Izmanto iespēju!".
            // Jo iespējas vairs nav.
            $selector.closest('.campaign-block').slideUp(300);
          }
        });

        // $selector.countdown('pause');
      }
    }
    return true;
  },
  destroy($selector) {
    if ($selector.length) {
      $selector.countdown('destroy');
    }
  },
  init(selector, endtime, locale, format) {
    const _this = this;
    this.load($(selector), endtime, locale, format);
    $(document).on('pjax:beforeReplace', () => {
      if ($(selector).length) {
        _this.destroy($(selector));
      }
    });
    $(document).on('pjax:end', () => {
      _this.load($(selector), endtime, locale, format);
    });
  },
};
