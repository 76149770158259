/* global globals */
import get from 'lodash/get';

module.exports = {
  track(event) {
    if (window.google_tag_manager) {
      this.dataLayerPush(event);
      return;
    }

    window.addEventListener('gtm_loaded', () => {
      this.dataLayerPush(event);
    });
  },
  dataLayerPush(event) {
    window.dataLayer = window.dataLayer || [];
    if (!window.dataLayer.length) {
      return;
    }

    window.dataLayer.push(event);
  },
  trackEcommerce(event, type, active, data, adapter) {
    //
    const amountToPay = (data.campaign ? data.campaign.amountToPay : data.amountToPay).toFixed(2);

    //
    const ecommerce = {
      currency: get(globals, 'currency', ''),
      value: amountToPay,
      items: [],
    };

    //
    if (adapter) {
      ecommerce.payment_type = adapter;
    }

    //
    const item = {
      item_id: `${type}_${active}`,
      item_name: `${type} ${active}`,
      price: amountToPay,
      item_category: type,
      item_category2: active,
      quantity: '1',
    };

    //
    if (data.campaign) {
      if (data.campaign.name) {
        ecommerce.coupon = data.campaign.name;
      }
      if (data.campaign.coupon) {
        item.coupon = data.campaign.coupon;
      }
      item.discount = (data.amountToPay - data.campaign.amountToPay).toFixed(2);
    }

    //
    ecommerce.items.push(item);
    this.track({ event, ecommerce });
  },
  init() {
    document.querySelectorAll('[data-gtm-track]').forEach((e) => {
      this.track(JSON.parse(e.dataset.gtmTrack));
    });
  },
};
