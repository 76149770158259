const _ = require('lodash');
const $ = require('jquery');
const modernizr = require('modernizr');
const popup = require('./popup');
const emoticons = require('./emoticons');
const campaigns = require('./campaigns');
const countdown = require('./countdown');

modernizr.enableClasses = false;

// Kad maksājumu skatā tiek uzspiests uz kāda no periodiem, un atveras skats ar kopsavilkumu
// un maksājumu metodēm, šeit tiek updeitots kopsavilkuma info.
// Skat. views/elements/payments/payment_period un views/elements/payments/payment_option
const updatePaymentInfo = function (data) {
  $('[data-summary-savings]').text(data.periodSavings);
  $('[data-summary-savings-text]').text(data.periodSavingsText);
  $('[data-summary-length]').text(data.periodLength);
  if (data.periodDiscount) {
    $('[data-summary-savings]').addClass('tcg');
    $('[data-summary-savings-text]').addClass('tcg');
  } else {
    $('[data-summary-savings]').removeClass('tcg');
    $('[data-summary-savings-text]').removeClass('tcg');
  }

  const $price = $('[data-summary-price]');
  $price.text(data.price);

  const $priceText = $('[data-summary-price-text]');
  $priceText.text(data.periodPriceText);

  const $periodPrice = $('[data-summary-period-price]');
  $periodPrice.text(data.periodPrice);

  const $periodPriceText = $('[data-summary-period-price-text]');
  $periodPriceText.text(data.periodPriceText);

  const $specialPrice = $('[data-summary-price-special]');

  if (typeof data.priceSpecial !== 'undefined') {
    $('.paymentOption-amount-special').removeClass('hide');
    $('[data-summary-savings]').addClass('tcg');
    $('[data-summary-savings-text]').addClass('tcg');
    $specialPrice.text(data.priceSpecial);
    $price.addClass('tdlt');
  } else {
    $('.paymentOption-amount-special').addClass('hide');
    $specialPrice.text('');
    $price.removeClass('tdlt');
  }

  $('[data-summary-footnote]').text(`* ${data.periodFootnote}`);

  // papildus parametri
  const additional = $('.paymentOption-additional');
  const additionalSmall = (!_.isEmpty(data.additionalSmall) ? data.additionalSmall : null);
  const additionalBig = (!_.isEmpty(data.additionalBig) ? data.additionalBig : null);

  // nav uzsetoti papildus parametri
  if (!additionalSmall && !additionalBig) {
    additional.addClass('hide');
  } else {
    // default vērtības, uzliek lai sākumā ir slēpti elementi
    const additionalSmallElement = additional.find('.paymentOption-smallText');
    const additionalBigElement = additional.find('.paymentOption-bigText');
    additional.removeClass('hide');
    additionalSmallElement.addClass('hide');
    additionalBigElement.addClass('hide');

    // pievieno vērtības
    if (additionalSmall) {
      additionalSmallElement.removeClass('hide');
      additionalSmallElement.text(additionalSmall);
    }

    if (additionalBig) {
      additionalBigElement.removeClass('hide');
      additionalBigElement.text(additionalBig);
    }
  }


  $('[data-payment-adapter]').each(function (k, item) {
    const adapter = $(this).data('paymentAdapter');
    const url = data.url.replace('/payments/', `/payments/${adapter}/`);
    $(this).attr('href', url);
  });

  emoticons.replaceEmoticons();
};
module.exports = {
  init() {
    const self = this;
    const active_period = $('.paymentPeriod.is-active');
    if (active_period.length > 0) {
      updatePaymentInfo(active_period.data());
    }

    let event = 'click';
    if (modernizr.touch) {
      event += ' touchend';
    }
    $(document).on(event, '.paymentPeriod, .paymentPeriod-confirm', function (e) {
      const $countdownSelector = $('.campaign-countdown-inner');
      if ($countdownSelector.length) {
        $countdownSelector.countdown('destroy');
      }
      const payment_steps = $('.paymentSteps').not('.paymentSteps--sms');
      const payment_steps_sms = $('.paymentSteps--sms');
      $('.paymentFooter-logos').addClass('is-hidden');
      $(this).closest('.paymentPeriod').siblings().removeClass('is-active');
      e.preventDefault();
      $(this).closest('.paymentPeriod').addClass('is-active');

      let payment_option = $('.paymentOption-container').not('.paymentOption-container--sms');
      if ($(this).hasClass('paymentPeriod--sms')) {
        payment_option = $('.paymentOption-container').filter('.paymentOption-container--sms');
			  payment_steps_sms.removeClass('is-hidden');
        payment_steps.addClass('is-hidden');
        payment_steps_sms.children('.paymentSteps-two').addClass('is-active');
      } else {
        payment_steps.removeClass('is-hidden');
        payment_steps_sms.addClass('is-hidden');
        payment_steps.children('.paymentSteps-two').addClass('is-active');
      }

      // atbalsta pushState
      if (history.pushState) {
        const payment_url = $(this).closest('.paymentPeriod').data('url');
        history.pushState({}, '', payment_url);
      }

      $('.paymentContainer-couponCodeInputForm').attr('action', $(this).closest('.paymentPeriod').data('url'));

      payment_option.removeClass('is-hidden');
      payment_option.siblings('.paymentOption-container').addClass('is-hidden');

      if (payment_option.length > 0) {
        $('html,body').animate({
          scrollTop: $(document).height(),
        }, { queue: false }, 'slow');
      }
      updatePaymentInfo($(this).closest('.paymentPeriod').data());
      self.initScrollHandler();
      campaigns.init();

      $('.paymentContainer-couponCodeText').removeClass('hide');
      $('.paymentContainer-couponCodeInputForm').addClass('hide');
    });

    $(document).on(event, '.paymentOption-button', function (e) {
      if ($(this).data('target')) {
        return;
      }
      $(this).addClass('has-loader');
    });

    $(document).on('pjax:end', (xhr, options) => {
      const valid_containers = ['#content', '#main-panel'];
      if (valid_containers.indexOf($.pjax.state.container) == -1) {
        return;
      }
      self.initScrollHandler();

      self.handlePageLoad();
    });
    $(document).on('click', '.paymentContainer-couponCodeText', function (e) {
      e.preventDefault();

      $(this).addClass('hide');
      $('.paymentContainer-couponCodeInputForm').removeClass('hide');
    });
  },
  handlePageLoad() {
    const active_period = $('.paymentPeriod.is-active');
    if (active_period.length > 0) {
      updatePaymentInfo(active_period.data());
    }

    const abo_message = $('.paymentContainer').children('.payments-aboMessage');
    if (abo_message.length > 0) {
      popup.subscriptionMessage();
    }
  },
  initScrollHandler() {
    const steps = $('.paymentSteps');
    if (steps.length > 0) {
      $(document).on('scroll', () => {
        if ($(window).scrollTop() > 65) {
          steps.addClass('paymentSteps--fixed');
        } else {
          steps.removeClass('paymentSteps--fixed');
        }
      });
    }
  },
};
