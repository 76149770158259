const _ = require('lodash');
const vex = require('vex');
const pjax = require('pjax');
const vexDialog = require('vexDialog');

let $vexModal = null;

// parametri ar loader
const vexParams = {
  content: `<div id="vex-content"><div class="loader"><div class="loader-icon"></div><div class="loader-text">${_.get(translations, 'please_wait', '')}</div></div></div>`,
};

vex.defaultOptions.className = 'vex-theme-default';
vexDialog.buttons.YES.text = _.get(translations, 'txt: Jā', '');
vexDialog.buttons.NO.text = _.get(translations, 'txt: Nē', '');

module.exports = {
  close() {
    vex.close();
  },
  confirm(modalMessage, modalCallback) {
    vexDialog.confirm({
      message: modalMessage,
      callback: modalCallback,
    });
  },
  alert(modalMessage, customYes) {
    const vexAlertDialog = vexDialog;
    if (customYes) {
      vexAlertDialog.buttons.YES.text = customYes;
    }
    vexAlertDialog.alert(modalMessage);

    // After showing 'customYes', set back to default
    vexDialog.buttons.YES.text = _.get(translations, 'txt: Jā', '');
  },
  videoModal(callbacks) {
    // jau atvērts logs
    if ($vexModal && !_.isEmpty($vexModal.data())) {
      return;
    }

    // uzreiz atverot rāda loading logu
    $vexModal = vex.open({
      contentCSS: { width: '680px', height: '400px' },
      escapeButtonCloses: false,
      overlayClosesOnClick: false,
      content: '<div class="videoPlayer-container"><div class="videoPlayer-loading has-loader"><div class="videoPlayer-loading-inner"></div></div><div class="videoPlayer videoPlayer--main"><video width="640" height="360" id="video-remote-stream"> Your browser does not support the video tag.</video></div><video muted id="video-local-stream" class="videoPlayer videoPlayer--preview" width="240" height="135"></video></div>',
      afterOpen: callbacks.afterOpen || null,
      afterClose: callbacks.afterClose || null,
    });
  },
  // izmanto ajax, lai iegūtu vex saturu
  // ja pjax == true, tad datu iegūšanai izmanto pjax
  // un container ir id vērtība, kurā ievietot datus
  // ajaxData - get parametri pjax un ajax pieprasījumiem
  // width - loga platums
  ajaxModal(url, pjax, container, ajaxData, width, classes, callback, closeCallback, type) {
    // jau atvērts logs
    if ($vexModal && !_.isEmpty($vexModal.data())) {
      //return;
      vex.close();
    }

    if (!ajaxData) {
      ajaxData = null;
    }

    let vexParamsExtended = null;
    let vexParamsTmp;

    // norāda platumu
    if (width) {
      vexParamsTmp = _.clone(vexParams);
      vexParamsExtended = _.assign(vexParamsTmp, {
        contentCSS: { width: width + 'px' }
      });
    }

    if (classes) {
      vexParamsTmp = vexParamsExtended || _.clone(vexParams);
      vexParamsExtended = _.assign(vexParamsTmp, {
        className: classes
      });
    }

    if (closeCallback) {
      vexParamsTmp = vexParamsExtended || _.clone(vexParams);
      vexParamsExtended = _.assign(vexParamsTmp, {
        afterClose: closeCallback
      });
    }

    if (type && type === 'strict') {
      vexParamsTmp = vexParamsExtended || _.clone(vexParams);
      vexParamsExtended = _.assign(vexParamsTmp, {
        escapeButtonCloses: false,
        overlayClosesOnClick: false,
        showCloseButton: false
      });
    }

    // atver vex
    $vexModal = vex.open((vexParamsExtended ? vexParamsExtended : vexParams));

    $(document).on('click', 'a[data-vex-close="1"]', (e) => {
      vex.close();
    });

    // pjax
    if (pjax) {
      $.pjax({
        url: url,
        container: container,
        data: ajaxData,
        push: false
      });
    }
    // ajax
    else {
      const request = $.ajax({
        type: "GET",
        data: ajaxData,
        url: url
      });

      // ar ajax veic pieprasījumu uz serveri un aizvieto saturu
      request.done(data => {
        try {
          var json = $.parseJSON(data);
          if(typeof json.url != 'undefined') {
            window.location = json.url;
          }
        } catch (e) {
          var json = false;
        }

        $vexModal.find('.loader').replaceWith(data);
        if (callback) {
          setTimeout(() => {
            callback();
          }, 500);
        }
      });
    }
  },
  isOpen() {
    // jau atvērts logs
    if ($vexModal && !_.isEmpty($vexModal.data())) {
      return true;
    }
    return false;
  }
};
