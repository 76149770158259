window.jQuery = window.$ = require('jquery');

require('./../../vendor/slick.js');
require('./../../vendor/validate.js');
require('./../../vendor/validate_helper.js');
require('./../../vendor/jquery.smartbanner.js');
window.vex = require('./../../vendor/vex.min.js');
require('./op');
require('./react/members-grid');
require('./react/video-player');
require('./fonts-loader');
