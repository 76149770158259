/* ===================================================
 * validate-helper.js
 * A jquery plugin that Helps to validate the form with 
 * validate.js and bootstrap
 * ===================================================
 */
; !function( $ ){
$.fn.extend({
validate: function( callback ) {

        // Checks if instance is already created 
        if ( this.data("instance") ) {
          return;
        }

        var $this = this;
        var temp=[];

        $this.find(".validate").each(function (i, el) {
          var $el = $(el);
          temp.push({name: $el.attr("name"), 
                     rules: $el.attr("data-rules"),
                     display: $el.attr("name")
                   });
        });
        // Create FormValidator object
        var validator = new FormValidator($this.attr('name'), temp, function(errors, event) {
          // Clear all error fields
		  var $form_group = $this.find(".form-group.error");
          $form_group.removeClass("error");
          $form_group.find(".error").remove();
          // Check for errors 
          if(errors.length > 0) {
              $.each(errors, function (index, err) {
                // Displays the erros message
                var $target = $this
                    .find("*[name='" + err.name + "']").closest('.form-group');
				$target.addClass('error');
				$target.prepend('<div class="error"><div class="inner">' + err.message + '</div></div>');
              });
             return false;
          } else {
            if (typeof callback === "function") {
              callback ($this, event ); //execute callback on form success
            }
          }
        });
		// add messages
		validator.setMessage('required_years', messages.confirm_years);
		validator.setMessage('required_terms', messages.confirm_terms);
		validator.setMessage('required_email', messages.required_email);
		validator.setMessage('required_password', messages.required_password);
		validator.setMessage('required_password_length', messages.required_password_length);

        this.data("instance", validator);
      }

      });

} ( window.jQuery );
